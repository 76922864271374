import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import AppointmentForm from './AppointmentForm'

class SupporterProfile extends Component {

  render() {
    return (
      <div>
        <h2>
          <FormattedMessage
            id='Supporter.Profile.Title'
            defaultMessage="Wählen Sie Ihre Gesprächsart"
          />
        </h2>
        <AppointmentForm
          appName={this.props.appName}
          locale={this.props.locale}
          clientprefix={this.props.clientprefix}
          contact={this.props.user}
          user={this.props.user}
          mailTheme={this.props.mailTheme}
          getInviteLink={this.props.getInviteLink}
          copyLink={this.props.copyLink}
          preview={this.props.preview}
          sendInviteToUser={this.props.sendInviteToUser}
          sendMail={this.props.sendMail} 
          showInfo={this.props.showInfo} />
      </div>
    )
  }
};

export default SupporterProfile;
