import React, { Component } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage, intlShape, defineMessages } from 'react-intl';

const localeMessages = defineMessages({
  'cancel': {
    id: "Supporter.ContactEditor.Cancel",
    defaultMessage: 'Abbrechen'
  },
  'save': {
    id: "Supporter.ContactEditor.Save",
    defaultMessage: 'Speichern'
  },
  'salutation': {
    id: "Supporter.ContactEditor.Salutation",
    defaultMessage: 'Anrede'
  },
  'sir': {
    id: "Supporter.ContactEditor.Sir",
    defaultMessage: 'Herr'
  },
  'madam': {
    id: "Supporter.ContactEditor.Madam",
    defaultMessage: 'Frau'
  },
  'name': {
    id: "Supporter.ContactEditor.Name",
    defaultMessage: 'Name'
  },
  'mail': {
    id: "Supporter.ContactEditor.Mail",
    defaultMessage: 'E-Mail'
  },
  'phone': {
    id: "Supporter.ContactEditor.Phone",
    defaultMessage: 'Telefon'
  },
  'age': {
    id: "Supporter.ContactEditor.Age",
    defaultMessage: 'Alter'
  },
  'address': {
    id: "Supporter.ContactEditor.Address",
    defaultMessage: 'Adresse'
  },
  'notes': {
    id: "Supporter.ContactEditor.Notes",
    defaultMessage: 'Notizen'
  },
  'editcontact': {
    id: "Supporter.ContactEditor.EditContact",
    defaultMessage: '{name} bearbeiten'
  }
});

class ContactEditor extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }
  
  constructor(props) {
    super(props);

    this.state = {
      contact: this.props.contact
    };
  }

  handleChange(field, value) {
    const contact = this.state.contact;
    contact[field] = value;

    this.setState({
      contact: contact
    });
  }

  render() {
    const actions = [
      <Button
        label={this.context.intl.formatMessage(localeMessages.cancel)}
        onClick={() => this.props.onClose()}
      />,
      <Button
        label={this.context.intl.formatMessage(localeMessages.save)}
        color="primary"
        onClick={() => this.props.onSave(this.state.contact)}
      />
    ];

    const editAttributes = [
      {
        id: 'gender', label: this.context.intl.formatMessage(localeMessages.sir),
        select: {
          'male': {label: this.context.intl.formatMessage(localeMessages.madam) },
          'female': {label: 'Frau' },
        }
      },
      { id: 'divider'},
      { id: 'name', label: this.context.intl.formatMessage(localeMessages.name) },
      { id: 'email', label: this.context.intl.formatMessage(localeMessages.mail) },
      { id: 'phone', label: this.context.intl.formatMessage(localeMessages.phone) },
      { id: 'age', label: this.context.intl.formatMessage(localeMessages.age) },
      { id: 'divider'},
      { id: 'address', label: this.context.intl.formatMessage(localeMessages.address) },
      { id: 'divider'},
      { id: 'about', label: this.context.intl.formatMessage(localeMessages.notes), muliline: true }
    ];

    const fields = editAttributes.map((field, ix) => {
      const fieldKey = field.id;

      const getField = () => {
        if (field.multiline) {
          return (
            <TextField
              key={fieldKey}
              name={fieldKey}
              multiline={true}
              value={this.state.contact[fieldKey]}
              label={field.label}
              onChange={(e, val) => this.handleChange(fieldKey, val)}
              rows={5}
            />
          );
        }
        else if (field.select) {
          const itemKeys = Object.keys(field.select);
          const items = itemKeys.map((selectKey) => {
            return (<MenuItem
              key={selectKey}
              value={selectKey}
              primaryText={field.select[selectKey].label} />);
          });
          return (
            <Select
              key={fieldKey}
              name={fieldKey}
              label={field.label}
              value={this.state.contact[fieldKey]}
              onChange={(e, ix) => {
                this.handleChange(fieldKey, itemKeys[ix])}
              }
            >
              {items}
            </Select>
          );
        }
        else if (fieldKey !== 'divider') {
          return (
            <TextField
              key={fieldKey}
              name={fieldKey}
              onChange={(e, val) => this.handleChange(fieldKey, val)}
              value={this.state.contact[fieldKey]}
              label={field.label}
            />
          );
        }
      }

      return (
        <div key={fieldKey + ix} style={{display: (fieldKey === 'divider' ? 'block' : 'inline-block')}}>
          {getField()}
        </div>
      );
    });

    return (
      <Dialog
        actions={actions}
        onRequestClose={this.props.onClose}
        contentStyle={{width:'80%', maxWidth: '90vw', maxHeight: '90vh', height: 'auto', minWidth: '50vw'}}
        title={<div>
          <Avatar
            icon={<AccountCircle />}
            size={36}
            style={{marginRight:'15px '}}
          />
          <span>
            <FormattedMessage
              id="Supporter.ContactEditor.EditContact"
              values={{
                name: this.props.contact.name
              }}
              defaultMessage="{name} bearbeiten"
            />
          </span>
        </div>}
        open={true}
      >
        {fields}
      </Dialog>
    );
  }
};

export default ContactEditor;
