
import React, { Component } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import DoneIcon from '@material-ui/icons/Done';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';

import {VolOff, CamOff, VolOn, MicOff, MicOn, Call, Cam} from './Icons';
import Streams from './Streams';
import Tracks from './Tracks';

import OpenInNew from '@material-ui/icons/OpenInNew';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import { FormattedMessage } from 'react-intl';

class CallScreen extends Component {
  speakerButton = null;
  micButton = null;
  camButton = null;
  speakerButton = null;
  menuTimeout = null;

  constructor(props) {
    super(props);
    this.speakerButton = React.createRef();
    this.micButton = React.createRef();
    this.camButton = React.createRef();
    this.state = {
      menuOpen: false,
      pip: null
    };
  }

  clearMenu() {
    this.setState({menuOpen: false});
  }

  toggleMenu(menu) {
    if (this.state.menuOpen === menu) {
      this.clearMenu();
    }
    else {
      this.showMenu(menu)
    }
  }

  showMenu(menu) {
    this.setState({menuOpen: menu});
  }

  getInitialState() {
    return 
  }

  render() {
    let hasPip = 'pictureInPictureEnabled' in document;
    return (
      <div className="callscreen" style={this.props.style}>
        <div className="options">
          <div className={"roundIcon muteRemote" + (this.props.muteRemote ? ' active' : '')}
            onClick={() => this.toggleMenu('speaker')}
            ref={this.speakerButton}>
            <div>
              {this.props.muteRemote && <VolOff />}
              {!this.props.muteRemote && <VolOn />}
            </div>
          </div>
          <div className={"roundIcon muteLocal" + (this.props.muteLocal ? ' active' : '')}
            onClick={() => this.toggleMenu('mic')}
            ref={this.micButton}>
            <div>
              {this.props.muteLocal && <MicOff />}
              {!this.props.muteLocal && <MicOn />}
            </div>
          </div>
          <div className={"roundIcon disableCam" + (this.props.disableCam ? ' active' : '')}
            onClick={() => this.toggleMenu('cam')}
            ref={this.camButton}>
            <div>
              {this.props.disableCam && <CamOff />}
              {!this.props.disableCam && <Cam />}
            </div>
          </div>
          <div className="roundIcon endCall"
            onClick={() => this.props.onStopCall()}>
            <div>
              <Call />
            </div>
          </div>
          {navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia &&
          <div className={"roundIcon disableDesktop"}
            onClick={() => this.props.onDisableDesktop()}>
            <div>
              {this.props.disableDesktop && <DesktopAccessDisabledIcon />}
              {!this.props.disableDesktop && <DesktopWindowsIcon />}
            </div>
          </div>}
          {document.fullscreenEnabled &&
          <div className={"roundIcon disableDesktop"}
            onClick={() => this.props.onToggleFullScreen()}>
            <div>
              {!document.fullscreenElement && <FullscreenIcon />}
              {document.fullscreenElement && <FullscreenExitIcon />}
            </div>
          </div>}
          {hasPip &&
          <div className={"roundIcon disableDesktop"}
            onClick={() => this.setState({pip: Math.random()})}>
            <div>
              <OpenInNew />
            </div>
          </div>}
        </div>
        <div className="camstreams">
          {this.props.hasConversation && this.props.callStatus !== 'stopping' && !this.props.useJitsi(this.props.conversation) && <Streams
            onError={(kind) => this.props.onError(kind)}
            onStreamsRunning={() => this.props.onStreamsRunning()}
            remotes={this.props.conversation.contacts}
            conversation={this.props.conversation}
            localStream={this.props.local.stream}
            callStatus={this.props.callStatus}
            optimizeVideo={this.props.optimizeVideo}
            local={this.props.local}
            bigPip={this.state.pip}
          />}
          {this.props.hasConversation && this.props.callStatus !== 'stopping' && this.props.useJitsi(this.props.conversation) && <Tracks
            onError={(kind) => this.props.onError(kind)}
            onStreamsRunning={() => this.props.onStreamsRunning()}
            conversation={this.props.conversation}
            currentSpeaker={this.props.currentSpeaker}
            muteRemote={this.props.muteRemote}
            trackCount={(this.props.conversation.tracks.filter(t => !t.disposed) || []).length}
            callStatus={this.props.callStatus}
            local={this.props.local}
            bigPip={this.state.pip}
          />}
        </div>
        <Menu
          id="speaker-menu"
          anchorEl={this.speakerButton.current}
          keepMounted
          open={this.state.menuOpen === 'speaker'}
          onClose={() => this.clearMenu()}
          getContentAnchorEl={null}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center'}}
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
        >
          {this.props.speakerDevices.map((s, ix) => {
            const selected = s.deviceId === this.props.currentSpeaker;
            return <MenuItem selected={selected} dense key={s.deviceId} onClick={() => {this.props.onSelectSpeaker(s.deviceId);} }>
              <ListItemIcon>
                <span>{!this.props.muteRemote && selected && <DoneIcon color='primary' />}</span>
              </ListItemIcon>
              <Typography>
                {s.label || <FormattedMessage
                  id="Supporter.CallScreen.SpeakerNr"
                  defaultMessage="Lautsprecher {number}"
                  values={{number: ix + 1}}
                />}
              </Typography>
            </MenuItem>;
          })}
          <Divider/>
          <MenuItem onClick={() => {this.props.onMuteRemote(); this.clearMenu();} }>
          {this.props.muteRemote && <ListItemIcon>
              <VolumeUpIcon />
            </ListItemIcon>}
          {!this.props.muteRemote && <ListItemIcon>
              <VolumeOffIcon />
            </ListItemIcon>}
            <Typography>{this.props.muteRemote ?
              <FormattedMessage
                id="Supporter.CallScreen.SoundOn"
                defaultMessage='Ton einschalten'
              /> :
              <FormattedMessage
                id="Supporter.CallScreen.SoundOff"
                defaultMessage='Ton ausschalten'
              />}
            </Typography>
          </MenuItem>
        </Menu>
        <Menu
          id="mic-menu"
          anchorEl={this.micButton.current}
          keepMounted
          open={this.state.menuOpen === 'mic'}
          onClose={() => this.clearMenu()}
          getContentAnchorEl={null}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center'}}
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
        >
          {this.props.audioDevices.map((s, ix) => {
            const selected = s.deviceId === this.props.currentMic;
            return <MenuItem selected={selected} dense key={s.deviceId} onClick={() => {this.props.onSelectMic(s.deviceId);} }>
              <ListItemIcon>
                <span>{!this.props.muteLocal && selected && <DoneIcon color='primary' />}</span>
              </ListItemIcon>
              <Typography>
                {s.label || <FormattedMessage
                  id="Supporter.CallScreen.MicNr"
                  defaultMessage="Mikrofon {number}"
                  values={{number: ix + 1}}
                />}
              </Typography>
            </MenuItem>;
          })}
          <Divider/>
          <MenuItem onClick={() => {this.props.onMuteLocal(); this.clearMenu();} }>
            {this.props.muteLocal && <ListItemIcon>
                <MicIcon />
              </ListItemIcon>}
            {!this.props.muteLocal && <ListItemIcon>
                <MicOffIcon />
            </ListItemIcon>}
            <Typography>{this.props.muteLocal ? 
              <FormattedMessage
                id="Supporter.CallScreen.MicOn"
                defaultMessage='Mikrofon einschalten'
              /> :
              <FormattedMessage
                id="Supporter.CallScreen.MicOff"
                defaultMessage='Mikrofon ausschalten'
              />}
            </Typography>
          </MenuItem>
        </Menu>
        <Menu
          id="cam-menu"
          anchorEl={this.camButton.current}
          keepMounted
          open={this.state.menuOpen === 'cam'}
          onClose={() => this.clearMenu()}
          getContentAnchorEl={null}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center'}}
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
        >
          {this.props.videoDevices.map((s, ix) => {
            const selected = s.deviceId === this.props.currentCam;
            return <MenuItem selected={selected} dense key={s.deviceId} onClick={() => {this.props.onSelectCam(s.deviceId);} }>
              <ListItemIcon>
                <span>{!this.props.disableCam && selected && <DoneIcon color='primary' />}</span>
              </ListItemIcon>
              <Typography>
                {s.label || <FormattedMessage
                  id="Supporter.CallScreen.CamNr"
                  defaultMessage="Kamera {number}"
                  values={{number: ix + 1}}
                />}
              </Typography>
            </MenuItem>;
          })}
          <Divider/>
          <MenuItem onClick={() => {this.props.onDisableCam(); this.clearMenu();} }>
            {this.props.disableCam && <ListItemIcon>
                <VideocamIcon />
            </ListItemIcon>}
            {!this.props.disableCam && <ListItemIcon>
                <VideocamOffIcon />
            </ListItemIcon>}
            <Typography>{this.props.disableCam ?
              <FormattedMessage
                id="Supporter.CallScreen.CamOn"
                defaultMessage='Kamera einschalten'
              /> :
              <FormattedMessage
                id="Supporter.CallScreen.CamOff"
                defaultMessage='Kamera ausschalten'
              />}
            </Typography>
          </MenuItem>
        </Menu>
      </div>
    );
  }
};

export default CallScreen;