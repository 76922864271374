import React, { Component } from 'react';

import './Streams.css'
import { Conversation } from '../App';
import Video from './Video';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

class Streams extends Component {
  isAnimating = false;
  pipBigScreen = false;

  state = {
    big: 'local',
    zoomed: false,
    stats: {},
    additionalVideoStreams: {},
    pip: null
  }
  
  updateVolumes() {
    if (!this.isAnimating) return;

    Conversation.getCallStats(this.props.conversation, (stats) => {
      this.props.conversation.contacts.forEach(c => {
        if (stats[c.id]) {
          const s = stats[c.id];

          if (s.local > 0 && s.local < 30) {
            if (s.local < 7) {
              this.props.optimizeVideo(c, 'local', s.local);
            }
            else if (s.local > 24) {
              this.props.optimizeVideo(c, 'local', s.local);
            }
          }
          if (s.remote > 0 && s.remote < 30) {
            if (s.remote < 7) {
              this.props.optimizeVideo(c, 'remote', s.remote);
            }
            else if (s.remote > 24) {
              this.props.optimizeVideo(c, 'remote', s.remote);
            }
          }
        }
      });

      requestAnimationFrame(() => {
        this.setState({stats: stats});
      });

      setTimeout(() => {
        requestAnimationFrame(() => this.updateVolumes());
      }, 2000);
    });
  }

  setBigScreen(kind) {
    this.setState({big: kind});
  }
  
  getBigScreen() {
    const streamsOnline = this.getStreamsOnline();

    if (streamsOnline.length == 2) {
      if (this.state.big) {
        return this.state.big;
      }
      else {
        return 'remote';
      }
    }
    else if (streamsOnline.length == 1) {
      if (this.props.remoteStream) return 'remote';
      else if (this.props.localStream) return 'local';
    }
    else {
      return false;
    }
  }

  getStreamsOnline() {
    return this.props.remotes.map(r => r.stream).concat([this.props.localStream]).filter(s => {
      return s && s.active && s.getTracks().some(t => t.readyState === 'live');
    });
  }

  toggleZoom() {
    this.setState({
      zoomed: !this.state.zoomed
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const onlineStreams = this.getStreamsOnline();

    if (this.props.callStatus !== 'established' && onlineStreams.length > 1) {
      this.props.onStreamsRunning();
      if (!this.isAnimating) {
        this.isAnimating = true;
        setTimeout(() => this.updateVolumes(), 2000);
      }
    }
    else {
      if (this.getStreamsOnline().length < 2) {
        this.isAnimating = false;
      }
    }

    if (this.props.localStream) {
      const tracks = this.props.localStream.getVideoTracks();
      tracks.forEach((v, ix) => {
        if (this.props.local.screenTrack) {
          if (this.props.local.screenTrack.id === v.id) {
            const desktopStream = new MediaStream();
            desktopStream.addTrack(v);
            this.addStream(desktopStream, v);
          }
        }
      });

      const additionalVideoStreams = this.state.additionalVideoStreams;

      const trackIds = tracks.map(t => t.id);
      Object.keys(additionalVideoStreams).forEach(tid => {
        if (trackIds.indexOf(tid) === -1) {
          const stream = additionalVideoStreams[tid];
          delete additionalVideoStreams[tid];

          this.setState({
            additionalVideoStreams: additionalVideoStreams,
          });

          if (this.getBigScreen() === stream.id) {
            if (this.props.remoteStream) {
              this.setBigScreen(this.props.remoteStream.id);
            }
            else {
              this.setBigScreen('local');
            }
          }

          if (stream) stream.getTracks().forEach(t => t.stop());
        }
      });
    }
  }

  addStream(s, track) {
    const additionalVideoStreams = this.state.additionalVideoStreams;
    if (!(track.id in this.state.additionalVideoStreams)) {
      additionalVideoStreams[track.id] = s;

      this.setState({
        additionalVideoStreams: additionalVideoStreams,
      });
      
      // this.setBigScreen(s.id);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.bigPip !== this.props.bigPip) {
      this.pipBigScreen = true;
    }
    return true;
  }

  componentWillUnmount() {
    this.isAnimating = false;

    Object.values(this.state.additionalVideoStreams).forEach(s => s.getTracks().forEach(t => t.stop()));
  }

  setPip(e, s) {
    this.pipBigScreen = false;
    e.stopPropagation();
    if (this.state.pip === s.id) {
      if (document.pictureInPictureElement) { document.exitPictureInPicture() }
    }
    this.setState({pip: this.state.pip === s.id ? null : s.id});
  }

  render() {
    let hasPip = 'pictureInPictureEnabled' in document;
    let mainScreen = this.getBigScreen();
    const liveStreams = this.props.remotes.filter(r => r.stream && r.pc && r.stream.active && r.stream.getTracks().some(t => t.readyState === 'live'));
    const streams = liveStreams.map(r => {
      const isBigStream = (mainScreen === r.id);

      if (isBigStream && this.pipBigScreen) {
        setTimeout(() => this.setPip({stopPropagation: () => {}}, r.stream));
      }

      return (
        <div
          key={r.id}
          className={"remoteStreamContainer" + (isBigStream ? ' bigStream' : ' smallStream')}
          onClick={() => mainScreen === r.id ? this.toggleZoom() : this.setBigScreen(r.id)}
          style={isBigStream ? {} : {
            maxWidth: `${10}vw`,
            backgroundColor: 'black'
          }}
        >
          <div className="streamoptions"><div className="contactname"><span>{r.name}</span></div><div className="actions">{hasPip && !isBigStream && <div className="icon" onClick={(e) => this.setPip(e, r.stream)}><OpenInNewIcon fill='white' /></div>}</div></div>
          <Video onLeavePip={() => this.setState({pip: null})} pip={this.state.pip === r.stream.id} muted={true} className="remoteStream" stream={r.stream} onError={() => this.props.onError('remote', r)} />
        </div>
      );
    });

    Object.values(this.state.additionalVideoStreams).forEach(s => {
      const isBigStream = (mainScreen === s.id);

      if (isBigStream && this.pipBigScreen) {
        setTimeout(() => this.setPip({stopPropagation: () => {}}, s));
      }

      streams.push(<div
        key={s.id}
        className={"remoteStreamContainer" + (mainScreen === s.id ? ' bigStream' : ' smallStream')}
        onClick={() => mainScreen === s.id ? this.toggleZoom() : this.setBigScreen(s.id)}
        style={isBigStream ? {} : {
          maxWidth: `${10}vw`,
          backgroundColor: 'black'
        }}
      >
      <div className="streamoptions"><div className="contactname"><span>Screen</span></div><div className="actions">{hasPip && !isBigStream && <div className="icon" onClick={(e) => this.setPip(e, s)}><OpenInNewIcon fill='white' /></div>}</div></div>

        <Video onLeavePip={() => this.setState({pip: null})} pip={this.state.pip === s.id} className="remoteStream" muted={true} stream={s} onError={() => this.props.onError('remote')} />
      </div>);
    });

    if (mainScreen === 'local' && this.pipBigScreen) {
      setTimeout(() => this.setPip({stopPropagation: () => {}}, this.props.localStream));
    }

    return (
      <div className={
          "streams"
          + (this.props.remoteStream ? ' hasremotestream' : '')
          + (this.props.localStream ? ' haslocalstream' : '')
          + (this.state.zoomed ? ' zoomed' : '')
        }>
          {streams}
          {mainScreen && this.props.localStream &&
            <div className={"localStreamContainer" + (mainScreen === 'local' ? ' bigStream' : ' smallStream')}
            onClick={() => mainScreen === 'local' ? this.toggleZoom() : this.setBigScreen('local')}
            style={mainScreen === 'local' ? {} : {
              maxWidth: `${10}vw`,
              backgroundColor: 'black'
            }}
          >
          <div className="streamoptions"><div className="contactname"><span>{this.props.local.name}</span></div><div className="actions">{hasPip && mainScreen !== 'local' && <div className="icon" onClick={(e) => this.setPip(e, this.props.localStream)}><OpenInNewIcon fill='white' /></div>}</div></div>
            <Video onLeavePip={() => this.setState({pip: null})} pip={this.state.pip === this.props.localStream.id} className="localStream" stream={this.props.localStream} muted={true} onError={() => this.props.onError('local')} />
          </div>}
      </div>
    )
  }
};

export default Streams;