import React, { Component } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage, intlShape, defineMessages } from 'react-intl';

const localeMessages = defineMessages({
  cancel: {
    id: 'Supporter.ConfirmDelete.Cancel',
    defaultMessage: 'Abbrechen'
  },
  delete: {
    id: 'Supporter.ConfirmDelete.Delete',
    defaultMessage: 'Löschen'
  }
});


class ConfirmContactDeleteDialog extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  render() {
    const actions = [
      <Button
        label={this.context.intl.formatMessage(localeMessages.cancel)}
        onClick={this.props.onClose}
      />,
      <Button
        label={this.context.intl.formatMessage(localeMessages.delete)}
        color="primary"
        style={{color: "red"}}
        onClick={this.props.onDelete}
      />
    ];

    return (
      <Dialog
        actions={actions}
        onRequestClose={this.props.onClose}
        title={<div>
          <Avatar
            icon={<AccountCircle />}
            size={36}
            style={{marginRight:'15px '}}
          />
          <span>
            <FormattedMessage
              id="Supporter.ConfirmDelete.DeleteContact"
              values={{
                name: this.props.contact.name
              }}
              defaultMessage="Soll der Kontakt {name} dauerhaft gelöscht werden?"
            />
          </span>
        </div>}
        open={true}
      >
      </Dialog>
    );
  }
};

export default ConfirmContactDeleteDialog;
