import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { FormattedMessage, intlShape } from 'react-intl';

const preDefinedFields = {
  'personal': ['firstname', 'lastname', 'dob', 'sex'],
  'communication': ['tel'],
  'address': ['street', 'no', 'postal', 'city', 'country', ],
  'insurance': ['insurance', 'insuranceCardNo']
};

const styleTemplates = {
  'personal': {gridTemplateColumns: '2fr 2fr 1fr 1fr'},
  'communication': {gridTemplateColumns: '1fr 1fr'},
  'address': {gridTemplateColumns: '3fr 1fr 1fr 2fr 1fr'}
};

const translateValues = {
  'metadata.personal.sex': ['male', 'female']
};

function getCallMetadataField(callMetadata, k, props) {
  const metadataField = callMetadata[k];
  const value = (props.callMetadataValues||{})[k];
  
  const items = metadataField.values[props.locale].map((text, ix) => {
    const intlMessageId = "metadata." + k + ".values." + ix;
    return <MenuItem checked={(value + "") === (ix + "")} value={ix} key={ix}><FormattedMessage
    id={intlMessageId}
  /></MenuItem>
  });

  return <ListItem
    key={k}
    disabled={true}>
    <p style={{marginTop: 0}}>
      <metadataField.icon color="inherit" />
      <FormattedMessage
        id={"metadata." + k + ".title"}
      />
    </p>
    <Select
      value={value || ""}
      multiple={metadataField.type === 'multiple'}
      onChange={(e, ix, v) => props.onChange(k, e.target.value)}
    >
      {items}
    </Select>
  </ListItem>;
}

function getClientMetadataFields(metadata, intl, props) {
  const config = metadata.config;
  const values = metadata.values;

  const sections = config.map(s => {
    let fields = (preDefinedFields[s.type] || Object.keys(values[s])).map(field => {
      const translationKey = `metadata.${s.type}.${field}`;
      const readableField = intl.formatMessage({id: translationKey, defaultMessage: field});
      let value = (values[s.id] || {})[field] || '';

      if (translationKey in translateValues && translateValues[translationKey].indexOf(value) !== -1) {
        value = intl.formatMessage({id: `${translationKey}.${value}`, defaultMessage: value});
      }

      return <TextField
        margin="dense"
        key={field}
        label={readableField}
        fullWidth
        value={value}
        onInput={(e) => {
          const v = e.target.value;
          values[s.id] = Object.assign({}, values[s.id] || {}, {[field]: v});
          props.onChange('clientmetadata', values);
        }}
      />
    });

    return <ListItem key={s.id} style={{display:'grid', gridGap: '10px', gridTemplateColumns:  'repeat(auto-fit, minmax(186px, 1fr))', ...styleTemplates[s.type]}}>
      {fields}
    </ListItem>
  });

  return (<List className="callmetadata">
    {sections}
  </List>);
}

class CallMetadataForm extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  render() {
    const callMetadata = this.props.callMetadata || {};
    const clientMetadata = this.props.clientMetadata || false;

    const callElements = <List className="callmetadata">{Object.keys(callMetadata).map(k => getCallMetadataField(callMetadata, k, this.props))}</List>;
    const clientElements = !clientMetadata ? {} : getClientMetadataFields(clientMetadata, this.context.intl, this.props);

    return (
      <div>
        {!!clientMetadata && clientElements}
        {!!Object.keys(callMetadata).length && callElements}
      </div>
    )
  }
};

export default CallMetadataForm;
