import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Group from '@material-ui/icons/Group';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Dot from '@material-ui/icons/FiberManualRecord';
import Add from '@material-ui/icons/AddCircle';
import ListSubheader from '@material-ui/core/ListSubheader'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { FormattedMessage } from 'react-intl';

const greenA700 = '#009a3c';
const grey500 = '#9E9E9E';

class Conversation extends Component {
  render() {
    const conversation = this.props.conversation;
    const hasConversation = this.props.activeConversation && this.props.activeConversation.id === conversation.id;
    const messagesLength = conversation.messages ? conversation.messages.length : 0;
    const agent = conversation.group ? '' : conversation.contacts.length ? conversation.contacts[0].agent : conversation.offlineContacts && conversation.offlineContacts.length ? conversation.offlineContacts[0].agent : '';

    return (<ListItem
      alignItems="flex-start"
      key={conversation.id}
      className="contactListItem"
      onClick={(e) => this.props.onConversationClick(conversation)}
      style={
        Object.assign(
          {},
          hasConversation ? {fontWeight: 'bold', backgroundColor: "rgba(0, 0, 0, 0.1)"} : {},
          {
            cursor: 'pointer'
          })
      }
    >
      <ListItemAvatar>
        {conversation.group ? <Group htmlColor="#bdbdbd" style={{width: '40px', height: '40px'}}/>
          : <Avatar><AccountCircle style={{width: '100%', height: '100%'}}/></Avatar>}
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{style: hasConversation ? {fontWeight: 'bold'} : {}}}
        primary={conversation.name}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
            >
              {!conversation.group && <span style={{opacity:0.8, fontSize: '0.9em', whiteSpace: 'nowrap'}}>{(agent ? Object.values(agent.browser.name) : '')} {(agent ? Object.values(agent.os.name) : '')}</span>}
              {conversation.group && <span style={{opacity:0.8, fontSize: '0.9em', whiteSpace: 'nowrap'}}>
                <FormattedMessage
                  id="Supporter.ContactList.NoOfContactsOnline"
                  defaultMessage="{count} Teilnehmer online"
                  values={{
                    count: conversation.contacts.length
                  }}
                />
              </span>}
              <br/>
              <span style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                display: 'block'
              }}>{(messagesLength ? conversation.messages[messagesLength - 1].text  : '')}</span>
            </Typography>
          </React.Fragment>
        }
      />
      {!conversation.group && <Dot className="state" htmlColor={conversation.online ? greenA700 : 'lightgrey'} />}
    </ListItem>);
  }
}

class ConversationList extends Component {
  render() {
    const conversations = this.props.conversations.map((conversation) => {
      return (
        <Conversation
          key={conversation.id}
          conversation={conversation}
          onConversationClick={this.props.onConversationClick}
          activeConversation={this.props.activeConversation}
        />);
    }).reverse();

    return (
      <div>
        <List>
          <ListSubheader>{this.props.title}</ListSubheader>
          {conversations}
          {!conversations.length &&
            <ListItem disabled={true}>
              <FormattedMessage
                id="Supporter.ContactList.NoContact"
                defaultMessage="Kein Kontakt"
              />
            </ListItem>
          }
        </List>
        {!this.props.disableEdits && 
          <div>
            <Divider/>
            <Button
              label=""
              fullWidth={true}
              color="primary"
              onClick={() => this.props.onNew({
                patient: true
              })}
              icon={<Add color={grey500} />}
            />
          </div>}
      </div>
    )
  }
};

export default ConversationList;
