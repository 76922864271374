import React, {Component} from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { intlShape, defineMessages } from 'react-intl';

const localeMessages = defineMessages({
  logout: {
    id: 'Supporter.Account.Logout',
    defaultMessage: 'Abmelden'
  },
});

class AccountMenu extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      menuAnchorEl: null
    }
  }

  render() {
    return (
      <div style={{display:'inline-flex'}}>
        <Button onClick={(e) => this.setState({menuAnchorEl: e.currentTarget})} style={{color:'white'}}>{this.props.user.name}</Button>
        <Menu
          anchorEl={this.state.menuAnchorEl}
          open={!!this.state.menuAnchorEl}
          onClose={() => this.setState({menuAnchorEl: null})}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'top'}}
        >
          <MenuItem onClick={this.props.onLogout}>{this.context.intl.formatMessage(localeMessages.logout)}</MenuItem>
        </Menu>
      </div>
    );
  }
};

export default AccountMenu;
