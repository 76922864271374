import React, { Component } from 'react';

import './CallStatus.css';

import {Close} from './Icons';
import { FormattedMessage } from 'react-intl';

class CallStatus extends Component {
  render() {
    let callStatus = null;
    let actions = null;

    switch(this.props.callStatus) {
      case 'stopping':
      case 'stopped':
        callStatus = <div>
          <FormattedMessage
            id='Shared.CallStatus.Ended'
            defaultMessage='Anruf beendet'
          />
        </div>;
        actions=[
          <div key="reset">
            <div
              className="roundIcon"
              onClick={this.props.onResetCallStatus}
            >
              <Close />
            </div>
            <span>
              <FormattedMessage
                id='Shared.CallStatus.Close'
                defaultMessage='Schliessen'
              />
            </span>
          </div>
        ];
        break;
      case 'accepted':
      case 'recovering':
        callStatus = <div>
          <FormattedMessage
            id='Shared.CallStatus.Connecting'
            defaultMessage='Verbindung wird hergestellt…'
          />
        </div>;
        actions=[
          <div key="stop">
            <div
              className="roundIcon"
              onClick={this.props.onStopCall}
            >
              <Close />
            </div>
            <span>
              <FormattedMessage
                id='Shared.CallStatus.Cancel'
                defaultMessage='Abbrechen'
              />
            </span>
          </div>
        ];
        break;
      case 'calling':
        callStatus = <div>
          <FormattedMessage
            id='Shared.CallStatus.Ringing'
            defaultMessage='Klingeln…'
          />
        </div>;
        actions=[
          <div key="abort">
            <div
              className="roundIcon"
              onClick={this.props.onAbortCall}
            >
              <Close />
            </div>
            <span>
              <FormattedMessage
                id='Shared.CallStatus.Cancel'
                defaultMessage='Abbrechen'
              />
            </span>
          </div>
        ];
        break;
      case 'denied':
        callStatus = <div>
          <FormattedMessage
            id='Shared.CallStatus.Busy'
            defaultMessage='Besetzt'
          />
        </div>;
        actions=[
          <div key="reset">
            <div
              className="roundIcon"
              onClick={this.props.onResetCallStatus}
            >
              <Close />
            </div>
            <span>
              <FormattedMessage
                id='Shared.CallStatus.Cancel'
                defaultMessage='Abbrechen'
              />
            </span>
          </div>
        ];
        break;
      default:
        alert(this.props.callStatus);
    }

    return (
      <div className="callStatus frosted">
        {callStatus}
        <div className={"actions"}>
          {actions}
        </div>
      </div>
    );
  }
};

export default CallStatus;