import React, { Component } from 'react';

class LanguageSwitcher extends Component {

  render() {
    var langs = this.props.availableLocales.map(locale => {
    return (
        <li onClick={() => this.props.setLocale(locale.id)}
          key={locale.id}
          className={this.props.locale === locale.id ? 'active' : ''}
          data-lang={locale.id}>{locale.label}
        </li>
      );
    });
    return (
      <div className="languageswitcher">
        <ul>
          {langs.length > 1 ? langs : ''}
        </ul>
      </div>
    )
  }
};

export default LanguageSwitcher;
