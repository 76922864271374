import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { FormattedMessage, intlShape, formatMessage } from 'react-intl';

const moment = window.moment;

const preDefinedFields = {
  'personal': ['firstname', 'lastname', 'dob', 'sex'],
  'communication': ['tel'],
  'address': ['street', 'no', 'postal', 'city', 'country', ],
  'insurance': ['insurance', 'insuranceCardNo']
};

const translateValues = {
  'metadata.personal.sex': ['male', 'female']
};

class SaveConversation extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  state = {
    mailsent: false
  }

  componentDidMount() {
    moment.locale(this.context.intl.locale);
  }

  getTotalTime(events) {
    let totalTime = 0;
    let lastAccepted = null;
    events.forEach(e => {
      if (e.type === 'accepted') lastAccepted = e;
      if (e.type === 'callend' && lastAccepted) {
        totalTime += Math.abs(moment(lastAccepted.time).diff(moment(e.time)));
      }
    });
    return totalTime;
  }

  getTextMetadata(clientMetadata) {
    const { config, values } = clientMetadata;
    const intl = this.context.intl;

    const sections = config.map(s => {
      let fields = (preDefinedFields[s.type] || Object.keys(values[s])).map(field => {
        const translationKey = `metadata.${s.type}.${field}`;
        const readableField = intl.formatMessage({id: translationKey, defaultMessage: field});
        let value = (values[s.id] || {})[field] || '';
  
        if (translationKey in translateValues && translateValues[translationKey].indexOf(value) !== -1) {
          value = intl.formatMessage({id: `${translationKey}.${value}`, defaultMessage: value});
        }
  
        return `${readableField}: ${value}`;
      });
      
      return fields.join("\n");
    });

    return sections.join("\n");
  }

  getChatData(messages, local, conversation) {
    messages = messages.filter(m => "text" in m).sort((a, b)=> a.time.getTime() - b.time.getTime())
    return messages.map((m, i) => {
      const isLocal = (m.from === local.id) || m.from.id === local.id;
      const author = (isLocal ? local.name : conversation.name || 'Anonymous').trim();;
      const text = m.text;

      const showAuthor = (i === 0 || messages[i - 1].from !== m.from);
      return (showAuthor ? `\n${author}:\n` : '') +
      `${text}`;
    }).join("\n");
  }
  
  sendSummary() {
    const { events, sent, sendMail, conversation } = this.props;
    const online = (events.find(e => e.type === 'online') || {}).time;
    const offline = (events.find(e => e.type === 'offline') || {}).time;
    const firstCall = (events.find(e => e.type === 'accepted') || {}).time;
    const totalTime = this.getTotalTime(events);

    this.setState({mailsent: true});
    var maildata = {
      from: 'noreply@nextcare.pro <Nextcare>',
      to: this.props.user.email,
      subject: this.context.intl.formatMessage({id: 'Supporter.SummarySubject', defaultMessage: 'Nextcare Gesprächszusammenfassung'}),
      text: this.context.intl.formatMessage({
        id: 'Supporter.Summary',
        defaultMessage: "Gespräch mit {client} am {date}\n\n"
          + "Online: {online}\n"
          + "Offline: {offline}\n"
          + "Erster Anruf: {firstCall}\n"
          + "Gesprächszeit insgesamt: {totalTime}\n\n"
          + "Metadata:\n\n{metadata}\n\n"
          + "Chat:\n\n{chat}"
      }, {
        client: conversation.name,
        date: moment(online).format('DD.MM.Y'),
        online: online ? moment(online).format('HH:mm:ss') : '',
        offline: offline ? moment(offline).format('HH:mm:ss') : '',
        firstCall: firstCall ? moment(firstCall).format('HH:mm:ss') : '',
        totalTime: moment.utc(totalTime).format('HH:mm:ss'),
        metadata: conversation.clientMetadata ? this.getTextMetadata(conversation.clientMetadata) : '',
        chat: this.getChatData(conversation.messages, this.props.user, conversation)
      })
    };
    this.props.sendMail(maildata);
  }

  render() {
    const { events, sent, sendMail, conversation, user } = this.props;

    console.log("SAVE", user);

    const totalTime = this.getTotalTime(events);

    return <div className="message event remote" style={{
        color: '#666',
        textAlign: 'center',
        maxWidth: '45em',
        fontSize: '90%',
        margin: 'auto',
        lineHeight: '1.4em',
      }}>
      <p>Gesprächsdauer insgesamt: {moment.utc(totalTime).format('HH:mm:ss')}<br/>Sie können sich eine Zusammenfassung dieses Gesprächs per E-Mail zukommen lassen.</p>
      <Button onClick={() => this.sendSummary()} color="primary">{this.state.mailsent ? 'Gesprächsdaten erneut senden' : 'Gesprächsdaten zusenden'}</Button>
    </div>;
  }
};

export default SaveConversation;